import request from "../request";

// 更新添加风险
export function setRisk(data) {
    return request({
        method: "json",
        url: `/client/risk/save`,
        data,
    });
}
// 风险详情
export function riskDetail(id) {
    return request({
        method: "post",
        url: `/client/risk/detail`,
        data: { id },
    });
}
// 删除风险
export function delRisk(data) {
    return request({
        method: "post",
        url: `/client/risk/cancel`,
        data,
    });
}
// 风险列表
export function getRiskList({ clientId, page, pageSize = 10 }) {
    return request({
        method: "get",
        url: `/client/risk/list?clientId=${clientId}&pageNum=${page}&pageSize=${pageSize}`,
    });
}
