<template>
    <van-form
        ref="form"
        error-message-align="right"
        input-align="right"
        :readonly="!!riskData.id"
        :show-error="false"
        @submit="onSubmit"
    >
        <van-cell-group inset>
            <van-field
                is-link
                readonly
                label="类型"
                :value="getRiskName(riskData.lossRisk)"
                :rules="[{ required: true, message: '请选择类型' }]"
                @click="riskData.id ? '' : (show = true)"
            />

            <van-field
                v-model="riskData.riskRemarks"
                :autosize="{ maxHeight: 300, minHeight: 150 }"
                label="备注"
                class="my-textarea"
                maxlength="300"
                show-word-limit
                type="textarea"
                placeholder="备注"
                :rules="[{ required: true, message: '请填写备注' }]"
            />
            <van-action-sheet
                v-model="show"
                :actions="risk"
                close-on-click-action
                :close-on-click-overlay="true"
                cancel-text="取消"
                @select="riskData.lossRisk = $event.code"
            />
        </van-cell-group>

        <div class="btn-group">
            <van-button color="var(--secondMainColor)" native-type="button" @click="goBack">
                返回
            </van-button>
            <van-button
                v-if="!riskData.id"
                color="var(--mainColor)"
                native-type="submit"
                :loading="submitting"
            >
                提交
            </van-button>
        </div>
    </van-form>
</template>

<script>
import { setRisk, riskDetail } from "@/api/hoss/risk";
export default {
    components: {},
    props: {},
    data() {
        return {
            riskData: {
                id: "",
                lossRisk: "",
                riskRemarks: "",
            },
            risk: [],
            show: false,
            submitting: false,
        };
    },
    async created() {
        if (this.$route.query.id) {
            const { value } = await riskDetail(this.$route.query.id);
            this.riskData = value;
        }
        const { risk } = await this.$getDict();
        this.risk = risk.children;
    },
    methods: {
        getRiskName(code) {
            if (!code || !this.risk.length) return "";
            const item = this.risk.find((item) => item.code == code);
            return item ? item.name : "";
        },
        goBack() {
            this.$router.back(-1);
        },
        async onSubmit() {
            this.submitting = true;
            const { clientId, stage } = this.$route.query;
            await setRisk({ ...this.riskData, stage, clientId });
            this.submitting = false;
            this.goBack();
        },
    },
};
</script>

<style scoped lang="less">
.van-form {
    margin-bottom: 54px;
    .van-cell-group {
        margin: 10px;
        border-radius: 10px;
    }
    .btn-group {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        .van-button {
            flex: 1 0 50%;
            border-radius: 0;
        }
    }
}
</style>
